import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import nexo from '../../nexoClient';

import {
  Page,
  Layout,
  MenuButton,
  DataList,
  FormField,
} from '@nimbus-ds/patterns';
import {
  Accordion,
  Box,
  Button,
  Card,
  Checkbox,
  Icon,
  IconButton,
  Input,
  Popover,
  Select,
  Sidebar,
  Spinner,
  Tag,
  Text,
  Title,
  Toggle,
  Link,
  Tooltip,
  useToast,
  Textarea,
  Label,
  Alert,
} from '@nimbus-ds/components';
import {
  getSessionToken,
  getStoreInfo,
  goTo,
  navigateHeader,
} from '@tiendanube/nexo';
import { useTranslation } from 'react-i18next';
import {
  FormFieldType,
  FormFileTypes,
  IForm,
  IFormField,
  emptyFormPopup,
  empytForm,
  empytFormField,
} from '../../lib/interfaces/form.interfaces';
import apiIntance from '../../utils/apiUtils';
import { InputColor, LabelHelp } from '../../components';
import {
  CheckIcon,
  CodeIcon,
  DragDotsIcon,
  EditIcon,
  EllipsisIcon,
  ExclamationCircleIcon,
  ExternalLinkIcon,
  LockIcon,
  PlusCircleIcon,
  TrashIcon,
  UploadIcon,
} from '@nimbus-ds/icons';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';

// import dynamic from 'next/dynamic';
// const Editor = dynamic(() => import('@nimbus-ds/editor'), { ssr: false });
import Editor from '@nimbus-ds/editor';

const CustomFieldsPage: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [blockEditor, setBlockEditor] = useState(true);
  const [enabledHTMLEditor, setEnabledHTMLEditor] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [showCeosConfig, setShowCeosConfig] = useState(false);
  const [myForm, setMyForm] = useState<IForm>({ ...empytForm });
  const [dropboxCount, setDropboxCount] = useState(0);
  const [editFormField, setEditFormField] = useState<IFormField>({
    ...empytFormField,
  });
  const [editFormFieldOpen, setEditFormFieldOpen] = useState(false);
  const [editFormFieldIndex, setEditFormFieldIndex] = useState(-1);

  interface IFormFieldProps {
    field: IFormField;
    index: number;
  }

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('General.back')}` });
    handleGetFormData();
    setLoadingData(false);
    showCEOSConfig();
  }, []);

  const showCEOSConfig = async () => {
    try {
      const store = await getStoreInfo(nexo);
      if (store && store.id === `${1791472}`) {
        setShowCeosConfig(true);
      }
    } catch (error) {
      console.log(`error get store id`);
    }
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value, type } = event.target;
    const updatedFormData: any = { ...myForm };
    let currentField = updatedFormData;
    const fieldPath = name.split('.');

    fieldPath.forEach((field, index) => {
      if (index === fieldPath.length - 1) {
        if (field in currentField) {
          currentField[field] =
            type === 'checkbox'
              ? (event.target as HTMLInputElement).checked
              : value;
        }
      } else {
        currentField = currentField[field];
      }
    });

    setMyForm(updatedFormData);
  };

  const handleChangeEditFormField = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const editForm: any = { ...editFormField };
    editForm[name] =
      type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
    setEditFormField({ ...editForm });
    return false;
  };

  const handleChangeFormFieldStatus = (
    index: number,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    const fields = myForm.fields;
    fields[index].active = checked;
    setMyForm((prevState) => ({
      ...(prevState as IForm),
      fields: fields,
    }));
  };

  const handleGetFormData = async () => {
    try {
      const result = await apiIntance.get(`/forms`);
      if (result.data !== null) {
        let form = { ...result.data };
        if (!('popup' in form) || form.popup === null) {
          form = { ...form, popup: { ...emptyFormPopup } };
        }
        setMyForm({ ...empytForm, ...form });
        setBlockEditor(false);
      }

      if (
        myForm.dropbox_token &&
        myForm.dropbox_token !== '' &&
        myForm.dropbox_token !== null
      ) {
        getDropboxCount();
      }
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: t('app.config.custom-form.page.alerts.error_get_data'),
        position: 4,
      });
    }
  };

  const getDropboxCount = async () => {
    try {
      const result = await apiIntance.get('/forms/dropbox/count');
      if (result.status === 200) {
        setDropboxCount(result.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInstallDropbox = async () => {
    if (myForm.store_id > 0) {
      const token = await getSessionToken(nexo);
      const store = await getStoreInfo(nexo);
      const client_id = process.env.REACT_APP_DROPBOX_CLIENTE_ID;
      const redirect_admin = btoa(
        `${process.env.REACT_APP_NS_APP_ID}#/configs`,
      );
      const api_url = btoa(`${process.env.REACT_APP_API_URI}`);
      const url = `${process.env.REACT_APP_DROPBOX_INSTALL_URL}?token=${token}&lang=${store.country}&client_id=${client_id}&redirect_admin=${redirect_admin}&api_url=${api_url}`;
      if (window.parent) {
        window.parent.location.href = url;
      } else {
        console.error('Não foi possível redirecionar para o site externo.');
      }
    } else {
      addToast({
        id: 'dropbox-token' + new Date().toISOString(),
        type: 'danger',
        text: t('app.config.custom-form.page.cards.uploads.redirect_error'),
        position: 4,
      });
    }
  };

  const handleAddFormField = (type: string) => {
    const fields = [...myForm.fields];
    const newField: IFormField = {
      active: true,
      custom_field: false,
      name: `${t(
        'app.config.custom-form.page.custom_fields.fields.new_custom_name',
      )}_${Date.now()}`,
      label: t(
        'app.config.custom-form.page.custom_fields.fields.new_custom_label',
      ),
      placeholder: '',
      options: '',
      mask: '',
      onblur: '',
      backendValidator: '',
      required: true,
      type: FormFieldType.TEXT,
      validator: '',
      order: myForm.fields.length,
    };
    if (type === 'custom') {
      newField.custom_field = true;
    } else if (type === 'line') {
      newField.custom_field = true;
      newField.name = `line_${Date.now()}`;
      newField.label = 'Linha';
      newField.type = FormFieldType.LINE;
    } else if (type === 'legend') {
      newField.name = `legend_${Date.now()}`;
      newField.custom_field = true;
      newField.label = t(
        'app.config.custom-form.page.custom_fields.fields.default_legend_label',
      );
      newField.type = FormFieldType.LEGEND;
    } else if (type === 'name') {
      newField.type = FormFieldType.TEXT;
      newField.name = 'name';
      newField.label = t(
        'app.config.custom-form.page.custom_fields.fields.default_name_label',
      );
      newField.placeholder = 'ex: Maria Perez';
    } else if (type === 'email') {
      newField.type = FormFieldType.EMAIL;
      newField.name = 'email';
      newField.label = 'E-mail';
      newField.placeholder = 'ex: seunome@email.com.br';
    } else if (type === 'identification') {
      newField.type = FormFieldType.TEXT;
      newField.name = 'identification';
      newField.required = true;
      newField.label = t(
        'app.config.custom-form.page.custom_fields.fields.default_identification_label',
      );
      newField.placeholder = 'ex: 00.000.000/0000-00';
      newField.mask = 'MASK_CNPJ';
    } else if (type === 'phone') {
      newField.type = FormFieldType.TEXT;
      newField.name = 'phone';
      newField.required = false;
      newField.label = t(
        'app.config.custom-form.page.custom_fields.fields.default_phone_label',
      );
      newField.placeholder = 'ex: (11) 97192-3930';
      newField.mask = 'MASK_WHATSAPP';
    } else if (type === 'birth_date') {
      newField.type = FormFieldType.TEXT;
      newField.name = 'birth_date';
      newField.required = true;
      newField.label = t(
        'app.config.custom-form.page.custom_fields.fields.default_birth_date_label',
      );
      newField.placeholder = '';
      newField.mask = 'MASK_DATE';
    } else if (type === 'password') {
      newField.type = FormFieldType.PASSWORD;
      newField.name = 'password';
      newField.required = true;
      newField.label = t(
        'app.config.custom-form.page.custom_fields.fields.default_password_label',
      );
      newField.placeholder = '';
    } else if (type === 'address') {
      newField.type = FormFieldType.TEXT;
      newField.name = 'address_address';
      newField.required = true;
      newField.label = 'Endereço';
      newField.placeholder = '';
    }

    let add = true;
    if (!newField.custom_field) {
      for (let x = 0; x < myForm.fields.length; x++) {
        const field = myForm.fields[x];
        if (field.name === newField.name) {
          addToast({
            id: 'new-field-unique' + new Date().toISOString(),
            type: 'danger',
            text: 'O campo unico já adicionado',
            position: 4,
          });
          add = false;
          return false;
        }
      }
    }

    if (add) {
      if (newField.name === 'address_address') {
        let index = myForm.fields.length;
        const newFields: IFormField[] = [
          {
            active: true,
            custom_field: false,
            name: 'address_zipcode',
            label: t(
              'app.config.custom-form.page.custom_fields.fields.default_address_zipcode_label',
            ),
            placeholder: 'Ex: 17514770',
            mask: 'MASK_CEP',
            options: '',
            required: true,
            type: FormFieldType.TEXT,
            validator: '',
            order: ++index,
          },
          {
            active: true,
            custom_field: false,
            name: 'address_address',
            label: t(
              'app.config.custom-form.page.custom_fields.fields.default_address_address_label',
            ),
            placeholder: 'Ex: Rua Leny Everson',
            options: '',
            mask: '',
            required: true,
            type: FormFieldType.TEXT,
            validator: '',
            order: ++index,
          },
          {
            active: true,
            custom_field: false,
            name: 'address_number',
            label: t(
              'app.config.custom-form.page.custom_fields.fields.default_address_number_label',
            ),
            placeholder: 'Ex: 123',
            options: '',
            mask: '',
            required: true,
            type: FormFieldType.TEXT,
            validator: '',
            order: ++index,
          },
          {
            active: true,
            custom_field: false,
            name: 'address_locality',
            label: t(
              'app.config.custom-form.page.custom_fields.fields.default_address_locality_label',
            ),
            placeholder: 'Ex: Morumbi',
            options: '',
            mask: '',
            required: true,
            type: FormFieldType.TEXT,
            validator: '',
            order: ++index,
          },
          {
            active: true,
            custom_field: false,
            name: 'address_city',
            label: t(
              'app.config.custom-form.page.custom_fields.fields.default_address_city_label',
            ),
            placeholder: 'Ex: São Paulo',
            options: '',
            mask: '',
            required: true,
            type: FormFieldType.TEXT,
            validator: '',
            order: ++index,
          },
          {
            active: true,
            custom_field: false,
            name: 'address_province',
            label: t(
              'app.config.custom-form.page.custom_fields.fields.default_address_province_label',
            ),
            placeholder: 'Ex: São Paulo',
            options: '',
            mask: '',
            required: true,
            type: FormFieldType.TEXT,
            validator: '',
            order: ++index,
          },
        ];

        for (let x = 0; x < newFields.length; x++) {
          fields.push(newFields[x]);
        }
      } else {
        fields.push(newField);
      }

      setMyForm((prevState) => ({
        ...(prevState as IForm),
        fields: fields,
      }));

      if (type !== 'line' && type !== 'address') {
        setEditFormField(newField);
        setEditFormFieldIndex(myForm.fields.length);
        setEditFormFieldOpen(true);
      }
    }
    return false;
  };

  const handleEdit = (index: number) => {
    setEditFormField(myForm.fields[index]);
    setEditFormFieldOpen(true);
    setEditFormFieldIndex(index);
    return false;
  };

  const handleRemoveField = (index: number) => {
    const form = { ...myForm };
    const field = form.fields[index];

    if (field.name.indexOf('address_') === 0) {
      const newFields: IFormField[] = [];
      for (let x = 0; x < form.fields.length; x++) {
        const f = form.fields[x];
        if (f.name.indexOf('address_') < 0) {
          newFields.push(f);
        }
      }
      form.fields = newFields;
    } else {
      form.fields.splice(index, 1);
    }
    setMyForm(form);
    return false;
  };

  const disabledDefaultField = (name: string): boolean => {
    return ['name', 'email'].includes(name);
  };

  const validateForm = (): boolean => {
    const fieldNames: string[] = []; // Array para armazenar os nomes dos campos
    myForm.fields.forEach((field: IFormField) => {
      if (fieldNames.includes(field.name)) {
        addToast({
          id: 'form-field' + new Date().toISOString(),
          type: 'danger',
          text: t('form_input_name_unique_message', { name: field.name }),
          position: 4,
        });
        return false;
      }
      fieldNames.push(field.name);
    });

    // Todos os campos são únicos
    return true;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    setLoadingData(true);
    if (validateForm()) {
      try {
        await apiIntance.post(`/forms`, myForm);
        addToast({
          id: 'form-save-success' + new Date().toISOString(),
          type: 'success',
          text: t('app.config.custom-form.page.alerts.form_success_title'),
          position: 4,
        });
      } catch (error) {
        addToast({
          id: 'form-save-error' + new Date().toISOString(),
          type: 'danger',
          text: t('app.config.custom-form.page.alerts.form_error_title'),
          position: 4,
        });
      }
    }
    setLoadingData(false);
    return false;
  };

  const showFormFieldExemple = (field: IFormField) => {
    if (field.active) {
      if (
        field.type === FormFieldType.TEXT ||
        field.type === FormFieldType.FILE ||
        field.type === FormFieldType.EMAIL ||
        field.type === FormFieldType.PASSWORD
      ) {
        return (
          <FormField label={field.label}>
            <Input type={field.type} placeholder={field.placeholder} />
          </FormField>
        );
      } else if (field.type === FormFieldType.SELECT) {
        const options = field.options?.split(',');
        return (
          <FormField.Select
            id=""
            name=""
            label={field.label}
            crossOrigin="anonymous"
          >
            {options?.map((option, index) => {
              return (
                <Select.Option key={index} label={option} value={option} />
              );
            })}
          </FormField.Select>
        );
      } else if (field.type === FormFieldType.TEXTAREA) {
        return (
          <FormField.Textarea
            crossOrigin="anonymous"
            id=""
            label={field.label}
            {...(field.placeholder
              ? { 'aria-placeholder': field.placeholder }
              : {})}
          />
        );
      } else if (field.type === FormFieldType.LINE) {
        return <hr />;
      } else if (field.type === FormFieldType.LEGEND) {
        return (
          <Text
            as="p"
            fontSize="highlight"
            fontWeight="bold"
            lineHeight="highlight"
          >
            {field.label}
          </Text>
        );
      }
    }
  };

  const handleSaveEdit = () => {
    const fields = myForm.fields;
    fields[editFormFieldIndex] = editFormField;

    setMyForm((prevState) => ({
      ...(prevState as IForm),
      fields: fields,
    }));

    setEditFormField({ ...empytFormField });
    setEditFormFieldOpen(false);
    setEditFormFieldIndex(-1);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    const fieldsCopy = [...myForm.fields];
    const [removedField] = fieldsCopy.splice(source.index, 1);
    fieldsCopy.splice(destination.index, 0, removedField);

    for (let x = 0; x < fieldsCopy.length; x++) {
      fieldsCopy[x].order = x + 1;
    }

    setMyForm({ ...myForm, fields: fieldsCopy });
  };

  const goToCreatePage = () => {
    goTo(nexo, '/pages/new');
    // nexo.dispatch({
    //   type: 'app/navigate/goto',
    //   payload: {
    //     pathToOldAdmin: '/pages/new',
    //   },
    // });
  };

  const handleTextCeos = async () => {
    try {
      const result = await apiIntance.get(`/forms/ceos/test`);
      if (result.data && result.data.enabled) {
        addToast({
          id: 'form-test-ceos' + new Date().toISOString(),
          type: 'success',
          text: t('app.config.custom-form.page.alerts.test_ceos_success'),
          position: 4,
        });
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    addToast({
      id: 'form-test-ceos' + new Date().toISOString(),
      type: 'danger',
      text: t('app.config.custom-form.page.alerts.test_ceos_error'),
      position: 4,
    });
  };

  const maskModelsFormats = [
    {
      tag: 'MASK_DATE',
      description: t(
        'app.config.custom-form.EditFormFieldModal.masks.mask_date_description',
      ),
    },
    {
      tag: 'MASK_CEP',
      description: t(
        'app.config.custom-form.EditFormFieldModal.masks.mask_cep_description',
      ),
    },
    {
      tag: 'MASK_WHATSAPP',
      description: t(
        'app.config.custom-form.EditFormFieldModal.masks.mask_phone_description',
      ),
    },
    {
      tag: 'MASK_CPF',
      description: t(
        'app.config.custom-form.EditFormFieldModal.masks.mask_cpf_description',
      ),
    },
    {
      tag: 'MASK_CNPJ',
      description: t(
        'app.config.custom-form.EditFormFieldModal.masks.mask_cnpj_description',
      ),
    },
    {
      tag: 'MASK_CPF_CNPJ',
      description: t(
        'app.config.custom-form.EditFormFieldModal.masks.mask_cpf_cnpj_description',
      ),
    },
    {
      tag: 'MASK_MONEY',
      description: t(
        'app.config.custom-form.EditFormFieldModal.masks.mask_money_description',
      ),
    },
  ];
  const validatorModelsFormats = [
    {
      tag: 'HUAPPS_VALIDA_CPF',
      description: t(
        'app.config.custom-form.EditFormFieldModal.validator.validator_cpf_description',
      ),
    },
    {
      tag: 'HUAPPS_VALIDA_CNPJ',
      description: t(
        'app.config.custom-form.EditFormFieldModal.validator.validator_cnpj_description',
      ),
    },
    {
      tag: 'HUAPPS_VALIDA_CPF_CNPJ',
      description: t(
        'app.config.custom-form.EditFormFieldModal.validator.validator_cpf_cnpj_description',
      ),
    },
  ];
  const ctas = [
    {
      action: 'identification',
      text: t('app.config.custom-form.page.ctas.add_field_documento'),
    },
    {
      action: 'password',
      text: t('app.config.custom-form.page.ctas.add_field_password'),
    },
    {
      action: 'phone',
      text: t('app.config.custom-form.page.ctas.add_field_phone'),
    },
    {
      action: 'birth_date',
      text: t('app.config.custom-form.page.ctas.add_birth_date'),
    },
    {
      action: 'address',
      text: t('app.config.custom-form.page.ctas.add_field_address'),
    },
    {
      action: 'custom',
      text: t('app.config.custom-form.page.ctas.add_field_custom'),
    },
    {
      action: 'legend',
      text: t('app.config.custom-form.page.ctas.add_field_legend'),
    },
    {
      action: 'line',
      text: t('app.config.custom-form.page.ctas.add_field_line'),
    },
  ];

  const MyFormField: React.FC<IFormFieldProps> = ({ field, index }) => {
    const ref = useRef<HTMLDivElement>(null);
    return (
      <Box
        ref={ref}
        key={index}
        draggable="true"
        id={`table-draggable-${index}`}
        display="flex"
        flexDirection="column"
        padding="4"
        gap="1"
        width="100%"
        borderWidth="none"
        borderStyle="solid"
        borderTopWidth="1"
        borderColor="neutral-surfaceHighlight"
        boxSizing="border-box"
      >
        <Box
          display="flex"
          width="100%"
          alignItems="flex-start"
          flexDirection="row"
          gap="2"
        >
          <Icon source={<DragDotsIcon />} cursor="grab" />
          <Box display="grid" width="100%" gap="1">
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              gap="2"
            >
              <Text as="p">
                {field.type === FormFieldType.LINE
                  ? t(
                      'app.config.custom-form.page.custom_fields.fields.type_line',
                    )
                  : field.label}
              </Text>
              <Toggle
                name={`custom-field-${index}`}
                disabled={field.name === 'name' || field.name === 'email'}
                onChange={(event) => handleChangeFormFieldStatus(index, event)}
                checked={field.active}
              />
            </Box>

            <Text as="p">{field.name}</Text>

            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              gap="2"
            >
              <Box display="flex" flexDirection="row" flexWrap="wrap" gap="1">
                {field.placeholder && field.placeholder !== '' ? (
                  <Tooltip
                    content={t(
                      'app.config.custom-form.page.custom_fields.fields.prop_placeholder_tooltip',
                    )}
                    position="bottom"
                  >
                    <Tag>{field.placeholder}</Tag>
                  </Tooltip>
                ) : (
                  <></>
                )}
                {field.required ? (
                  <Tag>
                    {t(
                      'app.config.custom-form.page.custom_fields.fields.prop_required_title',
                    )}
                  </Tag>
                ) : (
                  <></>
                )}

                {field.validator && field.validator !== '' ? (
                  <Tooltip
                    content={t(
                      'app.config.custom-form.page.custom_fields.fields.prop_validator_tooltip',
                    )}
                    position="bottom"
                  >
                    <Tag>{field.validator}</Tag>
                  </Tooltip>
                ) : (
                  <></>
                )}

                {field.onblur && field.onblur !== '' ? (
                  <Tooltip
                    content={t(
                      'app.config.custom-form.page.custom_fields.fields.prop_onblur_tooltip',
                    )}
                    position="bottom"
                  >
                    <Tag>{field.onblur}</Tag>
                  </Tooltip>
                ) : (
                  <></>
                )}

                {field.backendValidator && field.backendValidator !== '' ? (
                  <Tooltip
                    content={t(
                      'app.config.custom-form.page.custom_fields.fields.prop_backend_validator_tooltip',
                    )}
                    position="bottom"
                  >
                    <Tag>Backend Validator</Tag>
                  </Tooltip>
                ) : (
                  <></>
                )}
                {field.mask && field.mask !== '' ? (
                  <Tooltip
                    content={t(
                      'app.config.custom-form.page.custom_fields.fields.prop_mask_tooltip',
                    )}
                    position="bottom"
                  >
                    <Tag>{field.mask}</Tag>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Box>

              <Box display="flex" flexDirection="row" gap="1">
                <IconButton
                  size="36px"
                  source={<EditIcon size="small" />}
                  disabled={field.type !== FormFieldType.LINE ? false : true}
                  type="button"
                  onClick={() => handleEdit(index)}
                />
                <IconButton
                  size="36px"
                  source={<TrashIcon size="small" />}
                  disabled={disabledDefaultField(field.name)}
                  type="button"
                  onClick={() => handleRemoveField(index)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Page>
        <Page.Header title={t('app.config.custom-form.page.title')} />
        <Page.Body display="grid" gap="6">
          <Layout columns="2 - asymmetric" zIndex="500">
            <Layout.Section width="100%">
              <Box display="grid" gap="6" as="form" onSubmit={handleSubmit}>
                <Card padding="none">
                  <Card.Header>
                    <Box paddingTop="4" paddingLeft="4" paddingRight="4">
                      <Title as="h3">
                        {t('app.config.custom-form.page.cards.fields.title')}
                      </Title>
                    </Box>
                  </Card.Header>
                  <Card.Body padding="none">
                    <DataList>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="fieldList">
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {myForm.fields.map((field, index) => (
                                <Draggable
                                  key={index}
                                  draggableId={`field-${index}`}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <MyFormField
                                        key={index}
                                        field={field}
                                        index={index}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </DataList>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header
                    title={`${t(
                      'app.config.custom-form.page.cards.block_by_document.title',
                    )}`}
                  />
                  <Card.Body>
                    <Box display="grid" gap="6">
                      <Text>
                        {t(
                          'app.config.custom-form.page.cards.block_by_document.description',
                        )}
                      </Text>
                      <Checkbox
                        name="block_by_document"
                        disabled={loadingData}
                        checked={myForm.block_by_document}
                        onChange={handleChange}
                        label={`${t(
                          'app.config.custom-form.page.cards.block_by_document.block_by_document.label',
                        )}`}
                      />
                    </Box>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header
                    title={`${t(
                      'app.config.custom-form.page.cards.checkout.title',
                    )}`}
                  />
                  <Card.Body>
                    <Box display="grid" gap="6">
                      <Text>
                        {t(
                          'app.config.custom-form.page.cards.checkout.description',
                        )}
                      </Text>
                      <Checkbox
                        name="show_form_in_checkout"
                        disabled={loadingData}
                        checked={myForm.show_form_in_checkout}
                        onChange={handleChange}
                        label={`${t(
                          'app.config.custom-form.page.cards.checkout.show_form_in_checkout.label',
                        )}`}
                      />
                    </Box>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header
                    title={`${t(
                      'app.config.custom-form.page.cards.texts.title',
                    )}`}
                  />
                  <Card.Body>
                    <Box display="grid" gap="4">
                      <FormField>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Label>
                            <LabelHelp
                              label={t(
                                'app.config.custom-form.page.cards.texts.input_success_label',
                              )}
                              helpText={t(
                                'app.config.custom-form.page.cards.texts.input_success_label_help',
                              )}
                            />
                          </Label>
                          <Toggle
                            name="enabledHTMLEditor"
                            label="Modo HTML"
                            onChange={() =>
                              setEnabledHTMLEditor(!enabledHTMLEditor)
                            }
                            checked={enabledHTMLEditor}
                          />
                        </Box>
                        {blockEditor || enabledHTMLEditor ? (
                          <></>
                        ) : (
                          <Editor
                            value={myForm.message_success}
                            parser="html"
                            onChange={(html: any) => {
                              const temp = { ...myForm };
                              temp.message_success = html;
                              setMyForm({ ...temp });
                            }}
                          />
                        )}
                        <div
                          style={{
                            display: enabledHTMLEditor ? 'block' : 'none',
                          }}
                        >
                          <Textarea
                            lines={13}
                            id="message_success"
                            value={myForm.message_success}
                            required={true}
                            name="message_success"
                            disabled={loadingData}
                            onChange={handleChange}
                          />
                        </div>
                      </FormField>
                      <Checkbox
                        name="message_success_in_html"
                        disabled={loadingData}
                        checked={myForm.message_success_in_html}
                        onChange={handleChange}
                        label={`${t(
                          'app.config.custom-form.page.cards.texts.input_success_html_label',
                        )}`}
                      />
                      <FormField
                        label={
                          <LabelHelp
                            label={t(
                              'app.config.custom-form.page.cards.texts.input_error_label',
                            )}
                            helpText={t(
                              'app.config.custom-form.page.cards.texts.input_error_label_help',
                            )}
                          />
                        }
                      >
                        <Input
                          name="message_error"
                          disabled={loadingData}
                          value={myForm.message_error}
                          onChange={handleChange}
                        />
                      </FormField>
                      <FormField
                        label={
                          <LabelHelp
                            label={t(
                              'app.config.custom-form.page.cards.texts.input_cta_text_label',
                            )}
                            helpText={t(
                              'app.config.custom-form.page.cards.texts.input_cta_text_label_help',
                            )}
                          />
                        }
                      >
                        <Input
                          name="create_button_text"
                          disabled={loadingData}
                          value={myForm.create_button_text}
                          onChange={handleChange}
                        />
                      </FormField>
                    </Box>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header
                    title={`${t(
                      'app.config.custom-form.page.cards.config_actions.title',
                    )}`}
                  />
                  <Card.Body>
                    <Box display="grid" gap="4">
                      <FormField
                        label={
                          <LabelHelp
                            label={t(
                              'app.config.custom-form.page.cards.config_actions.input_form_page_label',
                            )}
                            helpText={t(
                              'app.config.custom-form.page.cards.config_actions.input_form_page_label_help',
                            )}
                          />
                        }
                      >
                        <Input
                          name="page_path"
                          disabled={loadingData}
                          value={myForm.page_path}
                          onChange={handleChange}
                        />
                      </FormField>

                      <FormField
                        label={
                          <LabelHelp
                            label={t(
                              'app.config.custom-form.page.cards.config_actions.input_redirect_label',
                            )}
                            helpText={t(
                              'app.config.custom-form.page.cards.config_actions.input_redirect_label_help',
                            )}
                          />
                        }
                        helpText={`${t(
                          'app.config.custom-form.page.cards.config_actions.input_redirect_help_text',
                        )}`}
                        showHelpText={true}
                        helpIcon={ExclamationCircleIcon}
                      >
                        <Input
                          name="redirect_page"
                          disabled={loadingData}
                          value={myForm.redirect_page}
                          onChange={handleChange}
                        />
                      </FormField>

                      <Alert
                        appearance="neutral"
                        title={`${t(
                          'app.config.custom-form.page.cards.config_actions.redirect_alert_title',
                        )}`}
                      >{`${t(
                        'app.config.custom-form.page.cards.config_actions.redirect_alert_text',
                      )}`}</Alert>
                      {/* message_success_in_whatsapp?: boolean | null;
                      message_success_in_whatsapp_number?: string | null;
                      message_success_in_whatsapp_message?: string | null; */}
                      <Checkbox
                        label={`${t(
                          'app.config.custom-form.page.cards.config_actions.message_success_in_whatsapp_label',
                        )}`}
                        name="message_success_in_whatsapp"
                        disabled={loadingData}
                        checked={
                          myForm.message_success_in_whatsapp ? true : false
                        }
                        onChange={handleChange}
                      />
                      <FormField
                        label={t(
                          'app.config.custom-form.page.cards.config_actions.message_success_in_whatsapp_number_label',
                        )}
                      >
                        <Input
                          name="message_success_in_whatsapp_number"
                          disabled={loadingData}
                          value={
                            myForm.message_success_in_whatsapp_number || ''
                          }
                          onChange={handleChange}
                        />
                      </FormField>
                      <FormField
                        label={t(
                          'app.config.custom-form.page.cards.config_actions.message_success_in_whatsapp_message_label',
                        )}
                      >
                        <Input
                          name="message_success_in_whatsapp_message"
                          disabled={loadingData}
                          value={
                            myForm.message_success_in_whatsapp_message || ''
                          }
                          onChange={handleChange}
                        />
                      </FormField>
                    </Box>
                  </Card.Body>
                </Card>

                {/* CARD POPUPS */}
                <Card>
                  <Card.Header
                    title={`${t(
                      'app.config.custom-form.page.cards.popup.title',
                    )}`}
                  />
                  <Card.Body>
                    <Box display="grid" gap="4">
                      <Checkbox
                        name="popup.active"
                        disabled={loadingData}
                        checked={myForm.popup.active}
                        onChange={handleChange}
                        label={`${t(
                          'app.config.custom-form.page.cards.popup.input_popup_active_label',
                        )}`}
                      />

                      <FormField
                        helpText={`${t(
                          'app.config.custom-form.page.cards.popup.input_popup_action_optional_help_text',
                        )}`}
                        showHelpText={true}
                        helpIcon={ExclamationCircleIcon}
                      >
                        <Checkbox
                          name="popup.action_optional"
                          disabled={loadingData}
                          checked={myForm.popup.action_optional}
                          onChange={handleChange}
                          label={`${t(
                            'app.config.custom-form.page.cards.popup.input_popup_action_optional_label',
                          )}`}
                        />
                      </FormField>

                      <Box display="grid" gap="2">
                        <Title as="h5">
                          {t(
                            'app.config.custom-form.page.cards.popup.subtitle_texts',
                          )}
                        </Title>
                        <Box display="grid" gap="4">
                          <FormField
                            label={
                              <LabelHelp
                                label={t(
                                  'app.config.custom-form.page.cards.popup.input_popup_message_label',
                                )}
                                helpText={t(
                                  'app.config.custom-form.page.cards.popup.input_popup_message_label_help',
                                )}
                              />
                            }
                          >
                            <Input
                              name="popup.message"
                              disabled={loadingData}
                              value={myForm.popup.message}
                              onChange={handleChange}
                            />
                          </FormField>
                          <FormField
                            label={
                              <LabelHelp
                                label={t(
                                  'app.config.custom-form.page.cards.popup.input_popup_go_to_login_label',
                                )}
                                helpText={t(
                                  'app.config.custom-form.page.cards.popup.input_popup_go_to_login_label_help',
                                )}
                              />
                            }
                          >
                            <Input
                              name="popup.cta_text_go_to_login"
                              disabled={loadingData}
                              value={myForm.popup.cta_text_go_to_login}
                              onChange={handleChange}
                            />
                          </FormField>
                          <FormField
                            label={
                              <LabelHelp
                                label={t(
                                  'app.config.custom-form.page.cards.popup.input_popup_go_to_register_label',
                                )}
                                helpText={t(
                                  'app.config.custom-form.page.cards.popup.input_popup_go_to_register_label_help',
                                )}
                              />
                            }
                          >
                            <Input
                              name="popup.cta_text_go_to_register"
                              disabled={loadingData}
                              value={myForm.popup.cta_text_go_to_register}
                              onChange={handleChange}
                            />
                          </FormField>
                          <FormField
                            label={
                              <LabelHelp
                                label={t(
                                  'app.config.custom-form.page.cards.popup.input_popup_text_close_label',
                                )}
                                helpText={t(
                                  'app.config.custom-form.page.cards.popup.input_popup_text_close_label_help',
                                )}
                              />
                            }
                          >
                            <Input
                              name="popup.cta_text_close"
                              disabled={loadingData}
                              value={myForm.popup.cta_text_close}
                              onChange={handleChange}
                            />
                          </FormField>
                        </Box>
                      </Box>

                      <Box display="grid" gap="2">
                        <Title as="h5">
                          {t(
                            'app.config.custom-form.page.cards.popup.subtitle_colors',
                          )}
                        </Title>
                        <Box display="grid" gap="6">
                          <Box
                            display="flex"
                            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                          >
                            <Box flex={{ md: '1' }} width="100%">
                              <InputColor
                                disabled={loadingData}
                                required={true}
                                value={myForm.popup.color_popup_background}
                                name="popup.color_popup_background"
                                label={`${t(
                                  'app.config.custom-form.page.cards.popup.input_popup_background_color_label',
                                )}`}
                                labelHelpText={`${t(
                                  'app.config.custom-form.page.cards.popup.input_popup_background_color_label_help',
                                )}`}
                                onChange={handleChange}
                              />
                            </Box>
                            <Box flex={{ md: '1' }} width="100%">
                              <InputColor
                                disabled={loadingData}
                                required={true}
                                value={myForm.popup.color_popup_text}
                                name="popup.color_popup_text"
                                label={`${t(
                                  'app.config.custom-form.page.cards.popup.input_popup_text_color_label',
                                )}`}
                                labelHelpText={`${t(
                                  'app.config.custom-form.page.cards.popup.input_popup_text_color_label_help',
                                )}`}
                                onChange={handleChange}
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                          >
                            <Box flex={{ md: '1' }} width="100%">
                              <InputColor
                                disabled={loadingData}
                                required={true}
                                value={myForm.popup.color_cta_background}
                                name="popup.color_cta_background"
                                label={`${t(
                                  'app.config.custom-form.page.cards.popup.input_popup_cta_background_color_label',
                                )}`}
                                labelHelpText={`${t(
                                  'app.config.custom-form.page.cards.popup.input_popup_cta_background_color_label_help',
                                )}`}
                                onChange={handleChange}
                              />
                            </Box>
                            <Box flex={{ md: '1' }} width="100%">
                              <InputColor
                                disabled={loadingData}
                                required={true}
                                value={myForm.popup.color_cta_text}
                                name="popup.color_cta_text"
                                label={`${t(
                                  'app.config.custom-form.page.cards.popup.input_popup_cta_text_color_label',
                                )}`}
                                labelHelpText={`${t(
                                  'app.config.custom-form.page.cards.popup.input_popup_cta_text_color_label_help',
                                )}`}
                                onChange={handleChange}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Card.Body>
                </Card>

                {/* CARD UPLOAD */}
                <Card>
                  <Card.Header
                    title={`${t(
                      'app.config.custom-form.page.cards.uploads.title',
                    )}`}
                  />
                  <Card.Body>
                    <Box display="grid" gap="2">
                      <Box>
                        <Text as="p">
                          {t(
                            'app.config.custom-form.page.cards.uploads.message',
                          )}
                        </Text>
                        <Text as="p" color="warning-interactive">
                          {t('app.config.custom-form.page.cards.uploads.alert')}
                        </Text>
                      </Box>
                      <Box
                        display="flex"
                        gap="2"
                        flexDirection="row"
                        alignItems="center"
                      >
                        {myForm.dropbox_token &&
                        myForm.dropbox_token !== '' &&
                        myForm.dropbox_token !== null ? (
                          <>
                            <Tag appearance="success">
                              <Icon
                                color="currentColor"
                                source={<CheckIcon size={12} />}
                              />
                              <Text color="success-textLow">
                                {t(
                                  'app.config.custom-form.page.cards.uploads.tag_success_message',
                                )}
                              </Text>
                            </Tag>

                            <Tag appearance="neutral">
                              <Icon
                                color="currentColor"
                                source={<UploadIcon size={12} />}
                              />
                              <Text>
                                {dropboxCount > 0
                                  ? `${dropboxCount} ${t(
                                      'app.config.custom-form.page.cards.uploads.tag_qty_files',
                                    )}`
                                  : t(
                                      'app.config.custom-form.page.cards.uploads.tag_no_file',
                                    )}
                              </Text>
                            </Tag>
                          </>
                        ) : (
                          <Button
                            type="button"
                            appearance="neutral"
                            onClick={handleInstallDropbox}
                          >
                            <Icon
                              color="currentColor"
                              source={<LockIcon size={12} />}
                            />
                            {t(
                              'app.config.custom-form.page.cards.uploads.cta_text',
                            )}
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Card.Body>
                </Card>

                {/* CARD CEOS */}
                {showCeosConfig ? (
                  <Card>
                    <Card.Header>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="2"
                      >
                        <Title as="h3">
                          {`${t(
                            'app.config.custom-form.page.cards.integrations.ceos.title',
                          )}`}
                        </Title>
                        {myForm.ceos_integration ? (
                          <Button
                            as="button"
                            type="button"
                            appearance="neutral"
                            onClick={handleTextCeos}
                          >
                            {`${t(
                              'app.config.custom-form.page.cards.integrations.ceos.cta_test',
                            )}`}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Card.Header>
                    <Card.Body>
                      <Box display="grid" gap="6">
                        <Box display="grid" gap="2">
                          <Text as="p">
                            {t(
                              'app.config.custom-form.page.cards.integrations.ceos.message',
                            )}
                          </Text>
                          <Text as="p">
                            {t(
                              'app.config.custom-form.page.cards.integrations.ceos.alert',
                            )}
                          </Text>
                          <Link
                            as="a"
                            href="https://ceos.digital/"
                            target="_blank"
                            appearance="primary"
                          >
                            {t(
                              'app.config.custom-form.page.cards.integrations.ceos.link',
                            )}
                            <Icon
                              source={<ExternalLinkIcon size="small" />}
                              color="currentColor"
                            />
                          </Link>
                        </Box>
                        <Checkbox
                          name="ceos_integration"
                          disabled={loadingData}
                          checked={myForm.ceos_integration}
                          onChange={handleChange}
                          label={`${t(
                            'app.config.custom-form.page.cards.integrations.ceos.inputs.ceos_integration.label',
                          )}`}
                        />
                        <FormField
                          label={t(
                            'app.config.custom-form.page.cards.integrations.ceos.inputs.ceos_tenant_id.label',
                          )}
                        >
                          <Input
                            name="ceos_tenant_id"
                            disabled={loadingData}
                            value={myForm.ceos_tenant_id}
                            onChange={handleChange}
                          />
                        </FormField>
                        <FormField
                          label={t(
                            'app.config.custom-form.page.cards.integrations.ceos.inputs.ceos_cod.label',
                          )}
                        >
                          <Input
                            name="ceos_cod"
                            disabled={loadingData}
                            value={myForm.ceos_cod}
                            onChange={handleChange}
                          />
                        </FormField>
                        <FormField
                          label={t(
                            'app.config.custom-form.page.cards.integrations.ceos.inputs.ceos_user.label',
                          )}
                        >
                          <Input
                            name="ceos_user"
                            disabled={loadingData}
                            value={myForm.ceos_user}
                            onChange={handleChange}
                          />
                        </FormField>
                        <FormField
                          label={t(
                            'app.config.custom-form.page.cards.integrations.ceos.inputs.ceos_token1.label',
                          )}
                        >
                          <Input
                            name="ceos_token1"
                            disabled={loadingData}
                            value={myForm.ceos_token1}
                            onChange={handleChange}
                          />
                        </FormField>
                        <FormField
                          label={t(
                            'app.config.custom-form.page.cards.integrations.ceos.inputs.ceos_token2.label',
                          )}
                        >
                          <Input
                            name="ceos_token2"
                            disabled={loadingData}
                            value={myForm.ceos_token2}
                            onChange={handleChange}
                          />
                        </FormField>
                      </Box>
                    </Card.Body>
                  </Card>
                ) : (
                  <></>
                )}

                <Button
                  disabled={loadingData}
                  appearance="primary"
                  type="submit"
                >
                  {loadingData ? (
                    <Spinner color="currentColor" size="small" />
                  ) : (
                    ''
                  )}
                  {t('General.save')}
                </Button>
              </Box>
            </Layout.Section>

            {/* PREVIA LATERAL */}
            <Layout.Section width="100%">
              <Box position="sticky" top="10px" display="grid" gap="6">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  gap="6"
                  zIndex="600"
                >
                  <Toggle
                    name="active"
                    disabled={loadingData}
                    checked={myForm.active}
                    onChange={handleChange}
                    label={`${t(`General.active`)}`}
                  />
                  <Popover
                    enabledHover={true}
                    arrow={false}
                    position="bottom-end"
                    zIndex="600"
                    content={
                      <Box width="100%" display="grid" gap="2">
                        {ctas.map((cta, index) => {
                          return (
                            <MenuButton
                              key={index}
                              label={cta.text}
                              startIcon={PlusCircleIcon}
                              onClick={() => handleAddFormField(cta.action)}
                              active={false}
                            />
                          );
                        })}
                      </Box>
                    }
                  >
                    <Button appearance="primary">
                      <Icon color="currentColor" source={<EllipsisIcon />} />
                      {t('app.config.custom-form.page.ctas.add_field_text')}
                    </Button>
                  </Popover>
                </Box>

                <Box zIndex="100" display="grid" gap="6">
                  <Card>
                    <Card.Header
                      title={`${t('app.config.custom-form.page.preview.title')}`}
                    />
                    <Card.Body>
                      <Box
                        borderColor="primary-interactive"
                        borderRadius="0-5"
                        borderStyle="dashed"
                        borderWidth="1"
                        padding="2"
                        width="100%"
                      >
                        <Box display="grid" gap="4">
                          {myForm.fields.map((field, index) => {
                            return field.active ? (
                              <Box key={index}>
                                {showFormFieldExemple(field)}
                              </Box>
                            ) : (
                              <></>
                            );
                          })}
                          <Button appearance="primary" disabled={true}>
                            {myForm.create_button_text}
                          </Button>
                        </Box>
                      </Box>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Header
                      title={`${t(
                        'app.config.custom-form.page.help-extra-page.title',
                      )}`}
                    />
                    <Card.Body>
                      <Box display="grid" gap="4">
                        <Text>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t(
                                'app.config.custom-form.page.help-extra-page.description',
                              ) as string,
                            }}
                          ></div>
                        </Text>
                        <Text>
                          {t(
                            'app.config.custom-form.page.help-extra-page.content_label',
                          )}
                        </Text>
                        <Box
                          backgroundColor="danger-surfaceHighlight"
                          padding="2"
                          borderRadius="2"
                        >
                          <Text
                            as="span"
                            color="danger-textLow"
                            fontWeight="bold"
                          >
                            {`<div id="huapps-b2b-form-content">Aguarde...</div>`}
                          </Text>
                        </Box>

                        <Button onClick={goToCreatePage} appearance="neutral">
                          {t(
                            'app.config.custom-form.page.help-extra-page.cta_add_page',
                          )}
                        </Button>
                      </Box>
                    </Card.Body>
                  </Card>
                </Box>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>

      {/* SIDEBAR DE FIELDS */}
      <Sidebar
        padding="none"
        open={editFormFieldOpen}
        onRemove={() => setEditFormFieldOpen(false)}
      >
        <Sidebar.Header
          padding="base"
          title={`${t('app.config.custom-form.EditFormFieldModal.title')}`}
        />
        <Sidebar.Body>
          <Box
            alignItems="stretch"
            borderColor="neutral-interactive"
            boxSizing="border-box"
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="flex-start"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="4"
              paddingX="4"
              width="100%"
            >
              {/* Campo status - true/false */}
              {(editFormField.type !== FormFieldType.LEGEND &&
                editFormField.name !== 'name' &&
                editFormField.name !== 'password' &&
                editFormField.name !== 'email' && (
                  <Checkbox
                    name="required"
                    checked={editFormField.required}
                    onChange={handleChangeEditFormField}
                    label={`${t(
                      'app.config.custom-form.EditFormFieldModal.input_required_label',
                    )}`}
                  />
                )) ?? <></>}

              {/* Campo name */}
              {editFormField.custom_field &&
              editFormField.type !== FormFieldType.LEGEND ? (
                <>
                  <FormField
                    label={`${t(
                      'app.config.custom-form.EditFormFieldModal.input_name_label',
                    )}`}
                  >
                    <Input
                      name="name"
                      value={editFormField.name}
                      onChange={handleChangeEditFormField}
                    />
                  </FormField>
                  <FormField
                    label={`${t(
                      'app.config.custom-form.EditFormFieldModal.input_type_label',
                    )}`}
                  >
                    <Select
                      onChange={handleChangeEditFormField}
                      id="edit-form-type"
                      name="type"
                      value={editFormField.type}
                    >
                      <Select.Option
                        label={`${t(
                          'app.config.custom-form.EditFormFieldModal.input_radio_type.text',
                        )}`}
                        value={FormFieldType.TEXT}
                      />
                      <Select.Option
                        label={`${t(
                          'app.config.custom-form.EditFormFieldModal.input_radio_type.textarea',
                        )}`}
                        value={FormFieldType.TEXTAREA}
                      />
                      <Select.Option
                        label={`${t(
                          'app.config.custom-form.EditFormFieldModal.input_radio_type.email',
                        )}`}
                        value={FormFieldType.EMAIL}
                      />
                      <Select.Option
                        label={`${t(
                          'app.config.custom-form.EditFormFieldModal.input_radio_type.file',
                        )}`}
                        value={FormFieldType.FILE}
                      />
                      <Select.Option
                        label={`${t(
                          'app.config.custom-form.EditFormFieldModal.input_radio_type.select',
                        )}`}
                        value={FormFieldType.SELECT}
                      />
                      <Select.Option
                        label={`${t(
                          'app.config.custom-form.EditFormFieldModal.input_radio_type.checkbox',
                        )}`}
                        value={FormFieldType.CHECKBOX}
                      />
                    </Select>
                  </FormField>
                </>
              ) : (
                <></>
              )}

              {/* Campo label */}
              <FormField
                label={`${t(
                  'app.config.custom-form.EditFormFieldModal.input_label_label',
                )}`}
              >
                <Input
                  name="label"
                  value={editFormField.label}
                  onChange={handleChangeEditFormField}
                />
              </FormField>

              {/* Campo placeholder */}
              {editFormField.type !== FormFieldType.LEGEND &&
              editFormField.type !== FormFieldType.FILE ? (
                <FormField
                  label={`${t(
                    'app.config.custom-form.EditFormFieldModal.input_placeholder_label',
                  )}`}
                >
                  <Input
                    name="placeholder"
                    value={editFormField.placeholder}
                    onChange={handleChangeEditFormField}
                  />
                </FormField>
              ) : (
                <></>
              )}

              {/* Campo options */}
              {editFormField.type === FormFieldType.SELECT ||
              editFormField.type === FormFieldType.FILE ? (
                <>
                  <FormField
                    label={`${t(
                      'app.config.custom-form.EditFormFieldModal.input_options_label',
                    )}`}
                    helpText={`${t(
                      'app.config.custom-form.EditFormFieldModal.input_options_help_text',
                    )}`}
                  >
                    <Input
                      name="options"
                      value={editFormField.options}
                      placeholder="Ex: valor 1, valor 2, ..."
                      onChange={handleChangeEditFormField}
                    />
                  </FormField>
                  <Box
                    display={
                      editFormField.type === FormFieldType.FILE
                        ? 'flex'
                        : 'none'
                    }
                    width="100%"
                    gap="1"
                    flexWrap="wrap"
                    flexDirection="row"
                  >
                    {FormFileTypes.map((tag, index) => (
                      <Tag key={index}>{tag}</Tag>
                    ))}
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="4"
              paddingTop="6"
              marginTop="6"
              width="100%"
              boxSizing="border-box"
            >
              <Box paddingX="4">
                <Title as="h5">
                  {t(
                    'app.config.custom-form.EditFormFieldModal.advanced_box.title',
                  )}
                </Title>
              </Box>
              <Accordion>
                {/* Campo validator and mask */}
                {[
                  FormFieldType.TEXT,
                  FormFieldType.TEXTAREA,
                  FormFieldType.SELECT,
                  FormFieldType.EMAIL,
                  FormFieldType.PASSWORD,
                ].includes(editFormField.type) ? (
                  <>
                    {editFormField.type === FormFieldType.TEXT &&
                    editFormField.name !== 'name' ? (
                      <Accordion.Item index="0">
                        <Accordion.Header
                          icon={
                            <Icon
                              color="currentColor"
                              source={<CodeIcon size="small" />}
                            />
                          }
                          title={`${t(
                            'app.config.custom-form.EditFormFieldModal.advanced_box.mask_title',
                          )}`}
                          subtitle={`${t(
                            'app.config.custom-form.EditFormFieldModal.advanced_box.mask_subtitle',
                          )}`}
                        />
                        <Accordion.Body padding="none">
                          <Box display="grid" gap="4" width="100%">
                            <Box paddingX="4">
                              <FormField
                                label={t(
                                  'app.config.custom-form.EditFormFieldModal.input_mask_label',
                                )}
                                helpText={`${t(
                                  'app.config.custom-form.EditFormFieldModal.input_mask_help_text',
                                )}`}
                                showHelpText={true}
                                helpIcon={ExclamationCircleIcon}
                              >
                                <Input
                                  value={editFormField.mask}
                                  name="mask"
                                  placeholder="Ex: 00/00/0000"
                                  onChange={handleChangeEditFormField}
                                />
                              </FormField>
                            </Box>

                            <DataList>
                              {maskModelsFormats.map((mask, index) => {
                                return (
                                  <Box
                                    key={index}
                                    display="flex"
                                    flexDirection="column"
                                    padding="2"
                                    gap="1"
                                    width="100%"
                                    borderStyle="solid"
                                    borderWidth="none"
                                    borderTopWidth="1"
                                    borderColor="neutral-surfaceHighlight"
                                    boxSizing="border-box"
                                  >
                                    <Text as="p" fontWeight="bold">
                                      {mask.tag}
                                    </Text>
                                    <Text as="p">{mask.description}</Text>
                                  </Box>
                                );
                              })}
                            </DataList>
                          </Box>
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : (
                      <></>
                    )}
                    <Accordion.Item index="1">
                      <Accordion.Header
                        icon={
                          <Icon
                            color="currentColor"
                            source={<CodeIcon size="small" />}
                          />
                        }
                        title={`${t(
                          'app.config.custom-form.EditFormFieldModal.advanced_box.validator_title',
                        )}`}
                        subtitle={`${t(
                          'app.config.custom-form.EditFormFieldModal.advanced_box.validator_subtitle',
                        )}`}
                      />
                      <Accordion.Body padding="none">
                        <Box display="grid" gap="4" width="100%">
                          <Box paddingX="4">
                            <FormField
                              label={t(
                                'app.config.custom-form.EditFormFieldModal.input_validator_label',
                              )}
                              helpText={`${t(
                                'app.config.custom-form.EditFormFieldModal.input_validator_help_text',
                              )}`}
                            >
                              <Input
                                value={editFormField.validator}
                                placeholder="validateEmail"
                                name="validator"
                                onChange={handleChangeEditFormField}
                              />
                            </FormField>
                          </Box>
                          {editFormField.name === 'identification' ? (
                            <>
                              <Box paddingX="4">
                                <Text as="span">
                                  {t(
                                    'app.config.custom-form.EditFormFieldModal.advanced_box.validator_description',
                                  )}
                                </Text>
                              </Box>
                              <DataList>
                                {validatorModelsFormats.map(
                                  (validator, index) => (
                                    <Box
                                      key={index}
                                      display="flex"
                                      flexDirection="column"
                                      padding="2"
                                      gap="1"
                                      width="100%"
                                      borderStyle="solid"
                                      borderWidth="none"
                                      borderTopWidth="1"
                                      borderColor="neutral-surfaceHighlight"
                                      boxSizing="border-box"
                                    >
                                      <Text as="p" fontWeight="bold">
                                        {validator.tag}
                                      </Text>
                                      <Text as="p">
                                        {validator.description}
                                      </Text>
                                    </Box>
                                  ),
                                )}
                              </DataList>
                            </>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <></>
                )}

                <Accordion.Item
                  index={
                    [
                      FormFieldType.TEXT,
                      FormFieldType.TEXTAREA,
                      FormFieldType.SELECT,
                      FormFieldType.EMAIL,
                      FormFieldType.PASSWORD,
                    ].includes(editFormField.type)
                      ? '2'
                      : '0'
                  }
                >
                  <Accordion.Header
                    icon={
                      <Icon
                        color="currentColor"
                        source={<CodeIcon size="small" />}
                      />
                    }
                    title={`${t(
                      'app.config.custom-form.EditFormFieldModal.advanced_box.onblur_title',
                    )}`}
                    subtitle={`${t(
                      'app.config.custom-form.EditFormFieldModal.advanced_box.onblur_subtitle',
                    )}`}
                  />
                  <Accordion.Body>
                    <Box display="grid" gap="4" width="100%">
                      <FormField
                        label={t(
                          'app.config.custom-form.EditFormFieldModal.input_onblur_label',
                        )}
                        helpText={`${t(
                          'app.config.custom-form.EditFormFieldModal.input_onblur_help_text',
                        )}`}
                        showHelpText={true}
                        helpIcon={ExclamationCircleIcon}
                      >
                        <Input
                          value={editFormField.onblur}
                          name="onblur"
                          onChange={handleChangeEditFormField}
                        />
                      </FormField>
                    </Box>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Box>
          </Box>
        </Sidebar.Body>
        <Sidebar.Footer padding="base">
          <Box
            alignItems="center"
            borderColor="neutral-interactive"
            boxSizing="border-box"
            display="flex"
            height="100%"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              disabled={loadingData}
              appearance="primary"
              onClick={handleSaveEdit}
            >
              {loadingData ? <Spinner color="currentColor" size="small" /> : ''}
              {t('General.save')}
            </Button>
          </Box>
        </Sidebar.Footer>
      </Sidebar>
    </>
  );
};

export default CustomFieldsPage;
