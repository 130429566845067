import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Icon,
  Pagination,
  Table,
  Link,
  Text,
  Tooltip,
  useToast,
  Sidebar,
  Input,
  Spinner,
  Alert,
  IconButton,
} from '@nimbus-ds/components';
import {
  EmptyMessage,
  InteractiveList,
  Layout,
  Page,
} from '@nimbus-ds/patterns';
import apiIntance from '../../utils/apiUtils';
import { goTo, navigateHeader } from '@tiendanube/nexo';
import nexo from '../../nexoClient';
import { useParams } from 'react-router-dom';
import {
  ICustomerGroup,
  ICustomerGroupRelation,
  ICustomerGroupRelationList,
} from '../../lib/interfaces/customer-group.interfaces';
import { PlusCircleIcon, SearchIcon, TrashIcon } from '@nimbus-ds/icons';

const CustomersGroupRelationsPage: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const { id } = useParams<{ id: string }>();
  const [searchSidebarOpen, setSearchSidebarOpen] = useState(false);
  const [customerGroup, setCustomerGroup] = useState<ICustomerGroup | null>(
    null,
  );
  const [relationsList, setRelationsList] =
    useState<ICustomerGroupRelationList | null>(null);
  const [relationsListData, setRelationsListData] = useState<
    ICustomerGroupRelation[]
  >([]);
  const [relationsIds, setRelationsIds] = useState<number[]>([]);

  const [searchLoading, setSearchLoading] = useState(false);
  const [searchCustomerNSValue, setSearchCustomerNSValue] = useState('');
  const [searchCustomerNSPage, setSearchCustomerNSPage] = useState('1');
  const [searchCustomerNSResult, setSearchCustomerNSResult] = useState<any[]>(
    [],
  );
  const [debouncedSearchCustomerNSValue, setDebouncedSearchCustomerNSValue] =
    useState('');

  useEffect(() => {
    if (debouncedSearchCustomerNSValue) {
      searchCustomerNS(debouncedSearchCustomerNSValue);
    }
  }, [debouncedSearchCustomerNSValue]);

  useEffect(() => {
    navigateHeader(nexo, {
      goTo: '/customers-group',
      text: `${t('General.back')}`,
    });

    getGroup();
    listRelations();
  }, []);

  const getGroup = async () => {
    try {
      const res = await apiIntance.get(`/customer-groups/${id}`);
      setCustomerGroup(res.data as ICustomerGroup);
    } catch (error) {
      setCustomerGroup(null);
    }
  };

  const listRelations = async () => {
    try {
      const res = await apiIntance.get(
        `/customer-groups/relations?group=${id}`,
      );
      setRelationsList(res.data);

      const { result } = res.data as ICustomerGroupRelationList;

      const relationsListData = result.map(
        (item: ICustomerGroupRelation) => item,
      );
      const relationsIds = result.map(
        (item: ICustomerGroupRelation) => item.external_customer_id,
      );

      setRelationsListData(relationsListData);
      setRelationsIds(relationsIds);
    } catch (error) {
      setRelationsList(null);
      setRelationsListData([]);
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchCustomerNSValue(value);

    // Implementação com `setTimeout`
    clearTimeout((window as any).searchTimeout);
    (window as any).searchTimeout = setTimeout(() => {
      setDebouncedSearchCustomerNSValue(value);
    }, 1000);
  };

  const searchCustomerNS = async (value: string) => {
    try {
      setSearchLoading(true);
      const result = await apiIntance.get(
        `/customer-groups/search-customers?q=${value}&page=${searchCustomerNSPage}`,
      );
      setSearchCustomerNSResult(result.data);
    } catch (error: any) {
      setSearchCustomerNSResult([]);
      addToast({
        id: 'search-customer-' + new Date().toISOString(),
        type: 'danger',
        text: error.response
          ? t(
              'app.config.customers-group.relations.page.search_customer_error',
              { error: error.response.data.message || '' },
            )
          : t(
              'app.config.customers-group.relations.page.search_customer_error_default',
            ),
      });
    }
    setSearchLoading(false);
  };

  const handleCloseSearch = () => {
    setSearchSidebarOpen(false);
  };

  const handleOpenCustomerInAdmin = (id: number) => {
    goTo(nexo, `/customers/${id}`);
  };

  const removeCustomerToGroup = async (customer_id: number | string) => {
    try {
      await apiIntance.delete(
        `/customer-groups/relations/customer/${customer_id}`,
      );
      addToast({
        id: `add-to-group-` + new Date().toISOString(),
        type: `success`,
        text: t(
          'app.config.customers-group.relations.page.customer_removed_success',
        ),
      });
      const updatedRelationsIds = relationsIds.filter(
        (valor) => valor !== customer_id,
      );
      const updatedRelationsListData = relationsListData.filter(
        (valor) => valor.external_customer_id !== customer_id,
      );
      setRelationsIds(updatedRelationsIds);
      setRelationsListData(updatedRelationsListData);
    } catch (error) {
      addToast({
        id: `add-to-group-` + new Date().toISOString(),
        type: `danger`,
        text: t(
          'app.config.customers-group.relations.page.customer_removed_error',
        ),
      });
    }
  };

  const handleAddCustomerToGroup = async (
    event: ChangeEvent<HTMLInputElement>,
    customer: any,
  ) => {
    const { checked } = event.target;
    if (customer.id) {
      if (!checked) {
        try {
          await removeCustomerToGroup(customer.id);
        } catch (error) {
          event.target.checked = !checked;
          addToast({
            id: `add-to-group-` + new Date().toISOString(),
            type: `danger`,
            text: t(
              'app.config.customers-group.relations.page.customer_removed_error',
            ),
          });
        }
      } else {
        try {
          await apiIntance.post(`/customer-groups/relations`, {
            store_id: null,
            external_customer_id: customer.id,
            external_customer_name: customer.name,
            external_customer_email: customer.email,
            customer_group_id: id,
          });

          addToast({
            id: `add-to-group-` + new Date().toISOString(),
            type: `success`,
            text: t(
              'app.config.customers-group.relations.page.customer_selected_success',
            ),
          });
          const updatedRelationsListData: ICustomerGroupRelation[] = [
            ...relationsListData,
            {
              store_id: -1,
              customer_group_id: `${id}`,
              external_customer_id: customer.id,
              external_customer_name: customer.name,
              external_customer_email: customer.email,
            },
          ];
          setRelationsListData(updatedRelationsListData);
          setRelationsIds([...relationsIds, customer.id]);
        } catch (error) {
          event.target.checked = !checked;
          addToast({
            id: `add-to-group-` + new Date().toISOString(),
            type: `danger`,
            text: t(
              'app.config.customers-group.relations.page.customer_selected_error',
            ),
          });
        }
      }
    }
  };

  return (
    <>
      <Page maxWidth="800px">
        <Page.Header
          title={t('app.config.customers-group.relations.page.title', {
            name: customerGroup?.name,
          })}
          buttonStack={
            <Box display="flex" flexDirection="row" gap="4">
              <Button
                appearance="primary"
                onClick={() => setSearchSidebarOpen(true)}
              >
                <Icon
                  source={<PlusCircleIcon size={14} />}
                  color="currentColor"
                />
                {t(
                  'app.config.customers-group.relations.page.cta_oepn_relation',
                )}
              </Button>
            </Box>
          }
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Text>
                {t('app.config.customers-group.relations.page.subtitle', {
                  name: customerGroup?.name,
                })}
              </Text>

              {relationsList && relationsListData.length ? (
                <Box display="grid" gap="4">
                  <Table>
                    <Table.Head>
                      <Table.Row key={0}>
                        <Table.Cell as="th" width="100px">
                          Id
                        </Table.Cell>
                        <Table.Cell as="th" width="150px">
                          {t('General.customer_name')}
                        </Table.Cell>
                        <Table.Cell as="th" width="180px">
                          {t('General.email')}
                        </Table.Cell>
                        <Table.Cell as="th" width="30px">
                          {''}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {relationsListData.map(
                        (item: ICustomerGroupRelation, index: number) => (
                          <Table.Row key={index}>
                            <Table.Cell as="td">
                              <Tooltip
                                content={t('app.page.show_customer_details')}
                                position="right"
                              >
                                <Link
                                  appearance="primary"
                                  as="button"
                                  onClick={() =>
                                    handleOpenCustomerInAdmin(
                                      item.external_customer_id,
                                    )
                                  }
                                >
                                  #{item.external_customer_id}
                                </Link>
                              </Tooltip>
                            </Table.Cell>
                            <Table.Cell as="td">
                              {item.external_customer_name}
                            </Table.Cell>
                            <Table.Cell as="td">
                              {item.external_customer_email}
                            </Table.Cell>
                            <Table.Cell as="td">
                              <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                  source={<TrashIcon size="small" />}
                                  size="24px"
                                  onClick={() =>
                                    removeCustomerToGroup(
                                      item.external_customer_id,
                                    )
                                  }
                                />
                              </Box>
                            </Table.Cell>
                          </Table.Row>
                        ),
                      )}
                    </Table.Body>
                  </Table>

                  <Pagination
                    activePage={relationsList.meta.page || 1}
                    onPageChange={listRelations}
                    pageCount={relationsList.meta.pages || 1}
                  />
                </Box>
              ) : (
                <Card>
                  <Card.Body>
                    <EmptyMessage
                      title={t(
                        'app.config.customers-group.relations.page.empty_title',
                        { name: `"${customerGroup?.name}"` },
                      )}
                      text={`${t(
                        'app.config.customers-group.relations.page.empty_message',
                        { name: `"${customerGroup?.name}"` },
                      )}`}
                      icon={<SearchIcon size={32} />}
                    />
                  </Card.Body>
                </Card>
              )}
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
      <Sidebar open={searchSidebarOpen} onRemove={handleCloseSearch}>
        <Sidebar.Header
          padding="base"
          title={`${t(
            'app.config.customers-group.relations.page.customer_search_title',
          )}`}
        />
        <Sidebar.Body>
          <Box display="grid" gap="4">
            <Box paddingX="4">
              <Input.Search
                disabled={searchLoading}
                value={searchCustomerNSValue}
                onChange={handleSearchChange}
                placeholder={`${t(
                  'app.config.customers-group.relations.page.customer_search_input_placeholder',
                )}`}
              />
            </Box>

            {searchLoading || searchCustomerNSResult.length ? (
              <InteractiveList>
                {searchLoading
                  ? Array.from({ length: 3 }, (_, i) => (
                      <InteractiveList.CheckboxItemSkeleton title="" key={i}>
                        <Text.Skeleton width="60%" />
                      </InteractiveList.CheckboxItemSkeleton>
                    ))
                  : searchCustomerNSResult.map(
                      (customer: any, index: number) => (
                        <InteractiveList.CheckboxItem
                          key={index}
                          title={customer.name}
                          description={customer.email}
                          checkbox={{
                            name: 'checkbox-element-' + index,
                            checked: relationsIds.includes(customer.id),
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            onChange: (event: ChangeEvent<HTMLInputElement>) =>
                              handleAddCustomerToGroup(event, customer),
                          }}
                        />
                      ),
                    )}
              </InteractiveList>
            ) : (
              <Box padding="4">
                <Alert appearance="primary">
                  {t(
                    'app.config.customers-group.relations.page.customer_search_empty_result',
                  )}
                </Alert>
              </Box>
            )}
          </Box>
        </Sidebar.Body>
      </Sidebar>
    </>
  );
};

export default CustomersGroupRelationsPage;
