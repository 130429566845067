export enum FormFieldType {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  TEXTAREA = 'textarea',
  FILE = 'file',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  LINE = 'line',
  LEGEND = 'legend',
}

export const FormFileTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/tiff',
  'image/svg+xml',
  'image/webp',
  'image/vnd.adobe.photoshop',
  'image/vnd.adobe.illustrator',
  'video/mp4',
  'video/mpeg',
  'video/quicktime',
  'video/webm',
  'video/avi',
  'text/plain', // .txt
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/pdf',
  'text/csv', // .csv
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/zip',
  'application/x-rar-compressed',
];

/**
 * Interface representando um campo de formulário.
 */
export interface IFormField {
  active: boolean; // Indica se o campo está ativo ou não
  custom_field: boolean; // Indica se o campo está ativo ou não
  name: string; // Nome do campo
  label: string; // Rótulo do campo
  placeholder?: string; // Texto de placeholder para o campo
  options?: string; // Indica se o campo é obrigatório
  required: boolean; // Indica se o campo é obrigatório
  type: FormFieldType; // Tipo do campo
  validator?: string; // Validador personalizado para o campo
  backendValidator?: string; // Validador personalizado para o campo
  onblur?: string; // Evento a ser chamado no onblur
  mask?: string;
  order: number; // Ordem de exibição do campo
}

export interface IFormPopup {
  active: boolean;
  action_optional: boolean;
  color_popup_background: string;
  color_popup_text: string;
  color_cta_background: string;
  color_cta_text: string;
  message: string;
  cta_text_go_to_register: string;
  cta_text_go_to_login: string;
  cta_text_close: string;
}

export interface IForm {
  _id?: string;
  store_id: number; // ID da loja associada ao campo
  script_external_id?: number | null;
  script_external_id_checkout?: number | null;
  active: boolean; // Indica se o formulário está ativo ou não
  show_form_in_checkout: boolean;
  block_by_document: boolean;
  message_success: string; // Mensagem de sucesso opcional
  message_success_in_html: boolean; // Mensagem de sucesso opcional
  message_error: string; // Mensagem de erro opcional
  fields: IFormField[]; // Lista de campos do formulário
  popup: IFormPopup;
  page_path: string; // Caminho da página opcional
  redirect_page: string; // Página de redirecionamento opcional
  dropbox_token?: string; // Token de acesso ao dropbox
  create_button_text: string; // Texto do botão de criação opcional
  function_after_user_created?: string | null; // função que será executada quando usuario for criado para adicionar a um grupo automaticamente.

  message_success_in_whatsapp?: boolean | null;
  message_success_in_whatsapp_number?: string | null;
  message_success_in_whatsapp_message?: string | null;

  ceos_integration?: boolean;
  ceos_tenant_id?: string; // email
  ceos_cod?: string; // codigo empresa
  ceos_user?: string; // codigo de usuario
  ceos_token1?: string; // email
  ceos_token2?: string; // senha
  ceos_access_token?: string; // senha

  createdAt?: string;
  updatedAt?: string;
}

export const empytFormField: IFormField = {
  active: true,
  name: '',
  label: '',
  placeholder: '',
  required: true,
  options: '',
  mask: '',
  validator: '',
  onblur: '',
  custom_field: false,
  type: FormFieldType.TEXT,
  order: 0,
};

export const emptyFormPopup: IFormPopup = {
  active: false,
  action_optional: false,
  color_popup_background: '#FFFFFF',
  color_popup_text: '#333333',
  color_cta_background: '#333333',
  color_cta_text: '#FFFFFF',
  message: 'Seja bem vindo!',
  cta_text_go_to_register: 'Criar conta',
  cta_text_go_to_login: 'Acessar conta',
  cta_text_close: 'Fechar e decidir depois',
};

export const empytForm: IForm = {
  store_id: -1,
  active: false,
  block_by_document: false,
  show_form_in_checkout: false,
  message_success: 'Pré cadastro realizado com sucesso',
  message_success_in_html: false,
  message_error:
    'Ocorreu um erro ao tentarmos processar os dados do pré cadastro, tente novamente mais tarde',
  popup: emptyFormPopup,
  function_after_user_created: null,
  fields: [
    {
      active: true,
      custom_field: false,
      name: 'name',
      label: 'Nome completo',
      placeholder: 'ex.: Maria Perez',
      required: true,
      type: FormFieldType.TEXT,
      order: 1,
    },
    {
      active: true,
      custom_field: false,
      name: 'identification',
      label: 'CNPJ',
      placeholder: 'ex.: 00.000.000/0000-00',
      mask: 'MASK_CNPJ',
      required: true,
      type: FormFieldType.TEXT,
      order: 1,
    },
    {
      active: true,
      custom_field: false,
      name: 'email',
      label: 'E-mail',
      placeholder: 'ex.: seunome@email.com.br',
      required: true,
      type: FormFieldType.EMAIL,
      order: 2,
    },
    {
      active: true,
      custom_field: false,
      name: 'phone',
      label: 'Telefone (opcional)',
      placeholder: 'ex.: (12) 12345-6789',
      mask: 'MASK_WHATSAPP',
      required: true,
      type: FormFieldType.TEXT,
      order: 3,
    },
    {
      active: true,
      custom_field: false,
      name: 'password',
      label: 'Senha',
      placeholder: '',
      required: true,
      type: FormFieldType.PASSWORD,
      order: 1,
    },
  ],
  page_path: '/criar-conta',
  redirect_page: '/',
  create_button_text: 'Cadastrar',

  message_success_in_whatsapp: false,
  message_success_in_whatsapp_number: '',
  message_success_in_whatsapp_message: '',

  ceos_integration: false,
  ceos_cod: '',
  ceos_user: '',
  ceos_tenant_id: '',
  ceos_token1: '',
  ceos_token2: '',
  ceos_access_token: '',
};
